


















import Vue from "vue";
import _ from "lodash";

const store = {
  options: [] as string[],
  selected: [] as string[],
  valueKey: "value",

  setOptions(options: string[]) {
    store.options = options;
  },
  setSelected(selected: string[]) {
    store.selected = selected;
  },
  setValueKey(key: string) {
    store.valueKey = key;
  },
  add(key: string) {
    const opt = _.map(store.options, opt => util.text(opt, store.valueKey));
    store.selected = _.without(store.selected, ...opt);
    store.selected.push(key);
  }
};

const util = {
  text(opt: any, key: string): string {
    const splittedKeys = key.split(".");
    let result = opt;

    for (const splittedKey of splittedKeys) {
      result = result[splittedKey] || "";
    }

    return result;
  }
};

export default Vue.extend({
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    textKey: {
      type: String,
      required: false,
      default: "text"
    },
    valueKey: {
      type: String,
      required: false,
      default: "value"
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 1000
    },
    defaults: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler(): void {
        this.cloneValue = _.cloneDeep(this.value);
      }
    }
  },
  computed: {
    isValid(): boolean {
      const optionIds = _.map(this.options, optionn => optionn.id);
      const selectCount =
        _.countBy(this.value, value => {
          return _.includes(optionIds, value);
        }).true || 0;

      return selectCount <= this.max && this.min <= selectCount;
    },
    selectedItems(): string[] {
      const optionIds = _.map(this.options, optionn => optionn.id);

      return _.filter(this.value, value => {
        return _.includes(optionIds, value);
      });
    }
  },
  data() {
    const cloneValue = _.cloneDeep(this.value) as string[];

    // デフォルト値操作
    const optionIds = _.map(this.options, option =>
      util.text(option, this.valueKey)
    );
    const selectCount =
      _.countBy(this.value, value => {
        return _.includes(optionIds, value);
      }).true || 0;
    if (selectCount === 0) {
      store.setOptions(this.$props.options);
      store.setSelected(this.$props.value);
      store.setValueKey(this.$props.valueKey);
      this.defaults.forEach(defaultId => {
        store.add(defaultId);
        this.$emit("add", defaultId);
      });

      // this.$emit('update', store.selected);
    }

    return {
      cloneValue,
      text: util.text
    };
  },
  methods: {
    handleClick(key: string): void {
      store.setOptions(this.$props.options);
      store.setSelected(this.$props.value);
      store.setValueKey(this.$props.valueKey);
      store.add(key);

      // TODO fixme
      (this as any).selectedItems.forEach(item => {
        this.$emit("remove", item);
      });

      this.$emit("add", key);
    },
    addValue(key: string) {
      store.setOptions(this.$props.options);
      store.setSelected(this.$props.value);
      store.setValueKey(this.$props.valueKey);
      store.add(key);

      // TODO fixme
      (this as any).selectedItems.forEach(item => {
        this.$emit("remove", item);
      });
      this.$emit("add", key);
    },
    isChecked(key: string): string {
      const check = _.find(this.$data.cloneValue, value => value === key);

      if (check) {
        return "checked";
      }

      return null;
    },
    optionDisabled(option): boolean {
      let isChecked = this.isChecked(this.text(option, this.valueKey));
      // case aw checked then not disabled
      if (isChecked) {
        return false;
      }
      return option.disabled || false;
    }
  }
});
